<template>
  <div>
    <h3 class="mt20">贴现信息</h3>
    <ul class="discount-info-list">
      <li>
        <span>贴现机构：</span>
        <em>{{discountForm.orgFullCnName}}</em>
      </li>
      <li>
        <span>贴现行号： </span>
        <em>{{discountForm.orgBankCode}}</em>
      </li>
      <li>
        <span>贴现金额（元）：</span>
        <em>{{discountForm.discountAmount}}</em>
      </li>
      <li>
        <span>申请日期：</span>
        <em>{{discountForm.applyTime}}</em>
      </li>
      <li>
        <span>贴现利率（%）：</span>
        <em>{{discountForm.discountRate}}</em>
      </li>
      <li>
        <span>到账金额（元）：</span>
        <em>{{discountForm.arrivalAmount}}</em>
      </li>
      <li>
        <span>平台服务费率（%）：</span>
        <em>{{discountForm.platformServiceRate}}</em>
      </li>
      <li>
        <span>应付服务费（元）：</span>
        <em>{{discountForm.payServiceCost}}</em>
      </li>
      <li>
        <span>收款账号：</span>
        <em>{{discountForm.recBankAccount}}</em>
      </li>
      <li>
        <span>收款账户行号：</span>
        <em>{{discountForm.recLegalNumber}}</em>
      </li>
      <li>
        <span>转让标记：</span>
        <em>{{discountForm.transferFlagName}}</em>
      </li>
      <li>
        <span>收款账户银行：</span>
        <em>{{discountForm.recBankName}}</em>
      </li>
      <li>
        <span>贴现种类：</span>
        <em>{{discountForm.discountType}}</em>
      </li>
      <li>
        <span>结算方式：</span>
        <em>{{discountForm.settlementModel === 'ST01' ? '线上' : '线下'}}</em>
      </li>
      <li>
        <span>付息方式：</span>
        <em>{{discountForm.payInterestType}}</em>
      </li>
      <li>
        <label>备注：</label>
        <em>{{discountForm.remark}}</em>
      </li>
    </ul>
    <!--缴纳平台服务费-->
    <h3 class="mt20">缴纳平台服务费</h3>
    <ul class="discount-info-list">
      <li>
        <span>账户名称：</span>
        <em>{{discountForm.lxAccountName}}</em>
      </li>
      <li>
        <span>银行全称：</span>
        <em>{{discountForm.lxBankName}}</em>
      </li>
      <li>
        <span>银行账户：</span>
        <em>{{discountForm.lxBankAccount}}</em>
      </li>
      <li>
        <span>大额行号：</span>
        <em>{{discountForm.lxLegalNumber}}</em>
      </li>
      <li>
        <label>缴纳凭证：</label>
        <img :src="discountForm.payVouchersUrl" alt="" class="payVouchersUrl">
      </li>
      <li>
        <label>缴纳时间：</label>
        <em>{{discountForm.payTime}}</em>
      </li>
    </ul>
    <!--发票信息-->
    <h3 class="mt30">发票信息</h3>
    <el-table :data="discountForm.invoiceList" border class="mt20">
      <el-table-column label="序号" type="index" width="50px"></el-table-column>
      <el-table-column label="发票代码" prop="invoiceCode" min-width="120px"></el-table-column>
      <el-table-column label="发票号码" prop="invoiceNum" min-width="100px"></el-table-column>
      <el-table-column label="开票日期" prop="invoiceDate" min-width="100px"></el-table-column>
      <el-table-column label="价税合计（元）" prop="taxIncludedAmount" min-width="120px">
        <template v-slot="scope">
          <span>{{parseFloat(scope.row.taxIncludedAmount).toFixed(2)}}</span>
        </template>
      </el-table-column>
      <el-table-column label="不含税金额（元）" prop="noTaxAmount" min-width="140px">
        <template v-slot="scope">
          <span>{{parseFloat(scope.row.noTaxAmount).toFixed(2)}}</span>
        </template>
      </el-table-column>
      <el-table-column label="购买方" prop="purchaserName" min-width="120px"></el-table-column>
      <el-table-column label="销售方" prop="sellerName" min-width="120px"></el-table-column>
      <el-table-column label="后六位校验码" prop="checkCode" min-width="120px"></el-table-column>
      <el-table-column label="操作" fixed="right" min-width="100px">
        <template v-slot="scope">
          <el-button type="primary" size="mini" @click="lookInvoice(scope.row)">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="bill-all">
      <span>价税合计（总计）：{{discountForm.taxTotal}}元</span>
      <span>发票张数：{{discountForm.invoiceTotal}}张</span>
    </div>
    <!--合同信息-->
    <el-descriptions title="合同信息" :column="2" class="mt30">
      <el-descriptions-item label="合同编号">{{discountForm.contractNo}}</el-descriptions-item>
      <el-descriptions-item label="合同日期">{{discountForm.contractSignDate}}</el-descriptions-item>
      <el-descriptions-item label="合同金额（元）">{{discountForm.contractAmt}}</el-descriptions-item>
      <el-descriptions-item label="合同信息分类">
        <span v-if="discountForm.tradeInfoType == 1">合同</span>
        <span v-else>订单</span>
      </el-descriptions-item>
      <el-descriptions-item label="贸易背景方式">
        <span v-if="discountForm.tradeMode == '1'">货物贸易</span>
        <span v-else-if="discountForm.tradeMode == '2'">服务贸易</span>
        <span v-else-if="discountForm.tradeMode == '3'">货服贸易</span>
      </el-descriptions-item>
      <el-descriptions-item label="合同附件">
         <a :href="discountForm.tradeFileUrl" target="_blank" class="accessory">{{discountForm.fileName}}</a>
      </el-descriptions-item>
    </el-descriptions>
    <!--母票基本信息-->
    <div class="title mt30">
      <h3>母票基本信息</h3>
      <el-button type="primary" @click="handleFaceView">票面预览</el-button>
      <el-button type="primary" @click="handleFaceDownload">票面下载</el-button>
    </div>
    <el-table :data="discountTicketInfoVOList" border class="mt20">
      <el-table-column prop="packageNo" label="票据包号"></el-table-column>
      <el-table-column prop="packageSum" label="票据包金额"></el-table-column>
      <el-table-column prop="acceptorPersonName" label="承兑人"></el-table-column>
      <el-table-column prop="expireTime" label="票据到期日"></el-table-column>
      <el-table-column prop="ticketRange" label="子票区间"></el-table-column>
    </el-table>
    <!--子票基本信息-->
    <template v-if="ticketChildVOList !== null">
      <h3 class="mt30">子票基本信息</h3>
      <el-table :data="ticketChildVOList" border class="mt20" v-if="isHaveChild === 1">
        <el-table-column prop="childTicketType" label="子票信息"></el-table-column>
        <el-table-column prop="ticketAffiliation" label="票据归属"></el-table-column>
        <el-table-column prop="childPackageSum" label="子票包金额"></el-table-column>
        <el-table-column prop="transferFlagName" label="转让标记"></el-table-column>
      </el-table>
    </template>
    <div class="btn">
      <el-button @click="handleBack">上一步</el-button>
      <el-button type="primary" @click="handleSave">确认提交</el-button>
    </div>
    <!--查看发票对话框-->
    <el-dialog :visible.sync="invoiceDialogVisible">
      <img :src="invoiceUrl" class="invoiceImg" alt="">
      <span slot="footer" class="dialog-footer">
		    <el-button @click="invoiceDialogVisible = false">关 闭</el-button>
		  </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "DiscountThree",
  props: ['id'],
  mounted() {
    // 获取数据
    this.getDiscountInfo()
  },
  data(){
    return {
      discountForm: {}, // 获取到的数据
      isHaveChild: 0, // 是否有子票信息（0=没有，1=有）
      discountTicketInfoVOList: [], // 母票基本信息
      ticketChildVOList:[], // 子票基本信息
      invoiceDialogVisible: false, // 控制查看发票对话框的显示与隐藏,
      invoiceUrl:'' // 查看发票图片地址
    }
  },
  methods: {
    // 获取数据
    async getDiscountInfo(){
      const {data: res} = await this.$auth.get('/cpiaoju-ticket/discount/applyDiscount', {params: {id: this.id, page: 3}});
      if (res.code !== 200) return this.$message.error(res.msg)
      this.discountForm = {...res.data}
      // 是否有子票信息（0=没有，1=有）
      this.isHaveChild = res.data.isHaveChild
      // 母票基本信息
      this.discountTicketInfoVOList = res.data.discountTicketInfoVOList
      // 子票基本信息
      this.ticketChildVOList = res.data.ticketChildVOList
    },
    // 查看发票
    lookInvoice(row){
      console.log(row)
      this.invoiceUrl = row.invoiceImgOssUrl
      this.invoiceDialogVisible = true
    },
    //返回
    handleBack(){
      this.$emit('handleBack', {activeStep: 2, id: this.id})
    },
    // 确认提交
    async handleSave(){
      let params = {
        page: 3,
        id: this.id
      }
      const {data: res} = await this.$auth.post('/cpiaoju-ticket/discount/addOrUpd', this.$qs.stringify(params))
      if (res.code !== 200) return this.$message.error(res.msg);
      this.$emit('handleSubmit', {activeStep: 4, handlerNo: res.data})
    },
    // 票面预览
    async handleFaceView(){
      let params = {
        paperBagNo: this.discountTicketInfoVOList[0].packageNo,
        section: this.discountTicketInfoVOList[0].ticketRange
      }
      const res = await this.$auth.post('/cpiaoju-ticket/common/ticketFaceView', this.$qs.stringify(params), {responseType: 'blob'});
      if (res.data.type === "application/pdf"){
        const blob = res.data;
        let link = document.createElement('a');
        link.setAttribute('target', '_blank');
        link.href = window.URL.createObjectURL(blob);
        link.click()
        URL.revokeObjectURL(link.href);
      }else{
        return this.$message.error('暂无票面信息')
      }
    },
    // 票面下载
    async handleFaceDownload(){
      let params = {
        paperBagNo: this.discountTicketInfoVOList[0].packageNo,
        section: this.discountTicketInfoVOList[0].ticketRange
      }
      const res = await this.$auth.post('/cpiaoju-ticket/common/ticketFaceDownload', this.$qs.stringify(params), {responseType: 'blob'});
      console.log(res)
      if (res.headers['content-disposition']){
        const blob = res.data;
        const fileName = window.decodeURI(res.headers['content-disposition'].split(';')[1].split('=')[1]); //这是下载的关键
        let link = document.createElement('a');
        let url = URL.createObjectURL(blob);
        link.href = url;
        link.download = fileName
        console.log(link)
        link.click()
        URL.revokeObjectURL(url)
      }else{
        return this.$message.error('暂无票面信息')
      }
    },
  }
}
</script>

<style lang="less" scoped>
  /*贴现信息*/
  .discount-info-list{
    display: flex;
    flex-wrap: wrap;
    li{
      width: 50%;
      margin-top: 20px;
      > span, label{
        display: inline-block;
        width: 150px;
        text-align: right;
      }
      span::before{
        display: inline-block;
        content: "*";
        color: #c70009;
        margin-right: 3px;
      }
      .payVouchersUrl{
        width: 100px;
      }
    }
    /*缴纳凭证*/
    /deep/ .upload-demo{
      display: inline-block;
    }
  }
  /*标题*/
  .title{
    h3{
      display: inline-block;
      margin-right: 10px;
    }
  }
  .btn{
    text-align: center;
    margin: 50px auto 20px;
  }
  .bill-all{
    padding: 15px 20px;
    border: 1px solid #EBEEF5;
    border-top: none;
    span{
      display: inline-block;
      font-size: 14px;
      color: #666;
      margin-top: 10px;
    }
    span:first-child{
      margin-right: 40px;
    }
  }
  .invoiceImg{
    width: 100%;
    height: 100%;
  }
</style>