<template>
  <div class="swiper-container" ref="cur">
    <div class="swiper-wrapper">
      <div class="swiper-slide"
           :class="{cur: currentIndex == index}"
           v-for="(carousel, index) in list"
           :key="index"
           @click="handleCarouselClick(index)">
	      <div class="carousel-shadow" v-if="carousel.status != '1'">
		      <span v-if="carousel.status == '3'">重复发票</span>
		      <span v-else>识别失败</span>
	      </div>
	      <a href="javascript:;"
           class="carousel-close"
           @click="carouselRemove(carousel,index)"></a>
        <img :src="carousel.invoiceImgOssUrl" v-if="carousel.invoiceImgOssUrl"/>
      </div>
    </div>
    <!-- 如果需要分页器 -->
    <div class="swiper-pagination"></div>
    <!-- 如果需要导航按钮 -->
    <!--<div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div>-->
  </div>
</template>

<script>
//引入Swiper
import Swiper from "swiper";
export default {
  name: "CarsouselSecond",
  props: ["list", 'id', 'CarouselCurrentIndex'],
  mounted() {
    this.currentIndex = this.CarouselCurrentIndex
  },
  data(){
    return {
      currentIndex: -1
    }
	},
  watch: {
    list: {
      //立即监听：不管你数据有没有变化，我上来立即监听一次
      //为什么watch监听不大list：因为这个数据从来没有发生变化（数据是父亲给的，父亲给的时候就是一个对象，对象里面该有的数据都是有的）
      immediate: true,
      handler() {
        //只能监听到数据已经有了，但是v-for动态渲染结构我们还是没有办法确定的，因此还是需要用nextTick
        this.$nextTick(() => {
          var mySwiper = new Swiper(this.$refs.cur, {
            slidesPerView: 'auto',
            spaceBetween: 20,
           
            // 如果需要分页器
            pagination: {
              el: ".swiper-pagination",
              //点击小球的时候也切换图片
              clickable: true,
            },
            // 如果需要前进后退按钮
            navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            },
          });
        });
      },
    },
  },
	methods: {
    // 监听轮播图当前的选择
    handleCarouselClick(index){
      this.currentIndex = index
	    this.$emit('handleCarouselClick', index)
    },
    carouselRemove(carousel,index){
      /*if (carousel.status === '2' || carousel.status === '3' || carousel.status === null){
        this.list.splice(index, 1);
        this.$emit('carouselRemove', this.list)
	      return false
      }else {
        const {data: res} = await this.$auth.post('/cpiaoju-ticket/ticket/deleteInvoice', this.$qs.stringify({invoiceNum:carousel.invoiceNum, id:this.id}))
        if(res.code !== 200) return this.$message.error(res.msg)
          this.currentIndex = 0
        this.$emit('carouselRemove', res.data)
      }*/
      this.list.splice(index, 1);
      this.currentIndex = -1
      this.$emit('carouselRemove', {item: this.list, index: -1})
    }
  }
};
</script>

<style lang="less" scoped>
	.swiper-container{
		margin-top: 20px;
		height: 100%;
		.swiper-slide{
			width: 20%;
			height: 70px;
			border: 1px solid #dedede;
			box-sizing: border-box;
			img{
				width: 100%;
				height: 100%;
			}
			.carousel-shadow{
				background: rgba(0,0,0, 0.3);
				text-align: center;
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				span{
					display: inline-block;
					line-height: 70px;
					color: #D6000F;
				}
			}
			.carousel-close{
				display: inline-block;
				background: url("~@/assets/img/ticket/close.png") no-repeat;
				width: 12px;
				height: 12px;
				position: absolute;
				top: 0;
				right: 0;
			}
		}
		&:hover{
			cursor: pointer;
		}
	}
	.swiper-slide.cur{
		border: 1px solid #D6000F;
	}
	/*.swiper-button-prev{*/
	/*	background: url("../Carousel/childrenCpn/Ticket/views/assets/img/ticket/swiperPrev.png");*/
	/*}*/
</style>
