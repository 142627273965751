<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>贴现管理</el-breadcrumb-item>
      <el-breadcrumb-item>贴现申请</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <!--步骤条-->
      <el-steps :active="activeStep"
                process-status="wait"
                finish-status="finish"
                align-center
                class="mt30">
        <el-step title="填写贴现信息"></el-step>
        <el-step title="上传贸易背景"></el-step>
        <el-step title="核对信息"></el-step>
        <el-step title="完成"></el-step>
      </el-steps>
      <DiscountOne v-if="activeStep === 1"
                   @handleSubmit="handleSubmit"/>
      <DiscountTwo v-else-if="activeStep === 2"
                   :id="id"
                   @handleBack="handleBack"
                   @handleSubmit="handleSubmit"/>
      <DiscountThree v-else-if="activeStep === 3"
                     :id="id"
                     @handleBack="handleBack"
                     @handleSubmit="handleSubmit"/>
      <Status v-else-if="activeStep === 4">
        <template v-slot:statusDescribe>
          <h3>贴现申请成功</h3>
          <h3 class="operation-number">操作编号：<span>{{handlerNo}}</span></h3>
          <el-button type="primary" @click="handleCopy" style="margin-top: 20px">点击复制</el-button>
        </template>
        <template v-slot:statusBtn>
          <el-button type="primary" size="medium" @click="handleSuccess">完成</el-button>
        </template>
      </Status>
    </el-card>
  </div>
</template>

<script>
  // 填写贴现信息
  import DiscountOne from "@/views/Ticket/routerCpn/discount/childCpn/DiscountOne";
  import DiscountTwo from "@/views/Ticket/routerCpn/discount/childCpn/DiscountTwo";
  import DiscountThree from "@/views/Ticket/routerCpn/discount/childCpn/DiscountThree";
  /*完成*/
  import Status from "@/views/Ticket/childrenCpn/Status";
  export default {
    name: "DiscountStep",
    components: {
      DiscountOne,
      DiscountTwo,
      DiscountThree,
      Status
    },
    data(){
      return {
        activeStep: 1, // 步骤条当前激活步骤
        handlerNo: '', // 成功后的操作编号
        id: '' // 第一步保存返回的
      }
    },
    methods: {
      // 完成
      handleSuccess(){
        this.$router.push({name: 'discountApply'})
      },
      //返回
      handleBack({activeStep, id}){
        console.log('返回', activeStep, id)
        this.activeStep = activeStep
        this.id = id
      },
      // 监听每一步保存
      handleSubmit({activeStep, id, handlerNo}){
        this.activeStep = activeStep
        this.id = id
        this.handlerNo = handlerNo
      },
      // 点击复制
      handleCopy(){
        this.$copyText(this.handlerNo).then(res => {
          this.$message.success('复制成功')
          this.successDialogVisible = false
          return false;
        },err => {
          this.$message.error('复制失败')
          this.successDialogVisible = true
        })
      },
    }
  }
</script>

<style scoped>

</style>