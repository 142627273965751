<template>
  <div>
    <!--票据基本信息-->
    <div class="title mt30">
      <h3>票据基本信息</h3>
      <el-button type="primary" @click="handleFaceView()">票面预览</el-button>
      <el-button type="primary" @click="handleFaceDownload()">票面下载</el-button>
    </div>
    <el-table :data="discountTicketInfoVOList" border class="mt20">
      <el-table-column prop="packageNo" label="票据包号"></el-table-column>
      <el-table-column prop="packageSum" label="票据包金额"></el-table-column>
      <el-table-column prop="acceptorPersonName" label="承兑人"></el-table-column>
      <el-table-column prop="expireTime" label="票据到期日"></el-table-column>
      <el-table-column prop="ticketRange" label="子票区间"></el-table-column>
    </el-table>
    <!--表单开始-->
    <el-form :model="discountForm"
             :rules="discountFormRules"
             ref="discountFormRef"
             inline
             label-width="170px"
             class="discount-ruleForm">
      <h3>贴现机构</h3>
      <el-form-item label="贴现机构：" prop="orgFullCnName">
        <el-input v-model="discountForm.orgFullCnName" @input="getDiscountOrg()"></el-input>
      </el-form-item>
      <el-form-item label="贴现行号：" prop="orgBankCode">{{discountForm.orgBankCode}}</el-form-item>
      <h3>贴现信息</h3>
      <el-form-item label="贴现金额（元）：" prop="discountAmount">
        <el-input v-model="discountForm.discountAmount" @input="getDiscountAmount"></el-input>
      </el-form-item>
      <el-form-item label="申请日期：" prop="applyTime">{{discountForm.applyTime}}</el-form-item>
      <el-form-item label="贴现利率（%）：" prop="discountRate">{{discountForm.discountRate}}%</el-form-item>
      <el-form-item label="到账金额（元）：" prop="arrivalAmount">{{discountForm.arrivalAmount}}</el-form-item>
      <el-form-item label="平台服务费率（%）：" prop="platformServiceRate">{{discountForm.platformServiceRate}}%</el-form-item>
      <el-form-item label="应付服务费（元）：" prop="payServiceCost">{{discountForm.payServiceCost}}</el-form-item>
      <el-form-item label="收款账号：" prop="recBankAccount">
        <el-select v-model="discountForm.recBankAccount"
                   placeholder="请选择"
                   @change="accountNumberChange(discountForm.recBankAccount)">
          <el-option v-for="item in accountNumberList"
                     :key="item.id"
                     :label="item.recBankAccount"
                     :value="item.recBankAccount"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="收款账户行号：" prop="recLegalNumber">{{discountForm.recLegalNumber}}</el-form-item>
      <el-form-item label="转让标记：" prop="transferFlag">
        <el-select v-model="discountForm.transferFlag" placeholder="请选择">
          <el-option label="可转让" value="EM00"></el-option>
          <el-option label="不可转让" value="EM01"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="收款账户银行：" prop="recBankName">{{discountForm.recBankName}}</el-form-item>
      <el-form-item label="贴现种类：" prop="discountType">{{discountForm.discountType}}</el-form-item>
      <el-form-item label="结算方式：" prop="settlementModel">
        <span v-if="discountForm.settlementModel === 'ST01'">线上</span>
        <span v-else-if="discountForm.settlementModel === 'ST02'">线下</span>
      </el-form-item>
      <el-form-item label="付息方式：" prop="payInterestType">{{discountForm.payInterestType}}</el-form-item>
      <el-form-item label="备注：" prop="remark">
        <el-input type="textarea" v-model="discountForm.remark"></el-input>
      </el-form-item>
      <h3>缴纳平台服务费</h3>
      <el-form-item label="账户名称：" prop="lxAccountName">{{discountForm.lxAccountName}}</el-form-item>
      <el-form-item label="银行全称：" prop="lxBankName">{{discountForm.lxBankName}}</el-form-item>
      <el-form-item label="银行账户：" prop="lxBankAccount">{{discountForm.lxBankAccount}}</el-form-item>
      <el-form-item label="大额行号：" prop="lxLegalNumber">{{discountForm.lxLegalNumber}}</el-form-item>
      <el-form-item label="缴纳凭证：" prop="payVouchersUrl">
        <el-upload action="http://117.122.218.200:11001/cpiaoju-ticket/front-company-register/uploadToOss"
                     accept=".jpg,.jpeg,.png"
                     class="upload-demo"
                     list-type="picture-card"
                     name="multipartFile"
                     :show-file-list="false"
                     :on-success="(response, file, fileList)=> discountForm.payVouchersUrl = response.data">
          <img class="upload-img bb"
               v-if="discountForm.payVouchersUrl"
               :src="discountForm.payVouchersUrl"
               alt=""/>
          <i class="el-icon-plus" v-else></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="缴纳时间：" prop="payTime">
        <el-date-picker
            v-model="discountForm.payTime"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            placeholder="选择日期">
        </el-date-picker>
      </el-form-item>
    </el-form>
    <div class="licence">
      <p><el-checkbox v-model="discountForm.isTechnologyServiceProtocol">同意签署以下协议</el-checkbox></p>
      <a :href="discountForm.technologyServiceProtocol" target="_blank">《技术服务费协议》</a>
    </div>
    <div class="upload-explain">
				<p>
					<img src="~@/assets/img/ticket/explain.png" alt="">温馨提示:
				</p>
				<span>
					(1).用于贴现申请的票据，需要承兑企业先在信息披露平台做票据披露。<br/>
          (2).票交所结算截止时间为17：30。如果提交贴现申请的时间过晚，该贴现申请可能会被清退。<br/>
          (3).票交所每天22:00 会日终清退当天机构银行未应答的贴现申请。
			</span>
    </div>
    <div class="btn">
      <el-button @click="handleBack">返回</el-button>
      <el-button type="primary" @click="handleSave">下一步</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "DiscountOne",
  mounted() {
    // 保存列表带过来的ID
    this.id = this.$route.query.id
    // 获取数据
    this.getDiscountInfo()
    // 获取收款账号
    this.getPassBank()
  },
  data(){
    return {
      id: '', // 列表返回的ID
      // ticketBizId: this.ticketBizId, // 第一步保存返回的
      // 表单数据
      discountForm: {
        orgFullCnName: '', // 贴现机构
        orgBankCode: '', // 贴现行号
        discountAmount: '', // 贴现金额（元）
        applyTime: '', // 申请日期
        discountRate: '', // 贴现利率
        arrivalAmount: '', // 到账金额（元）
        platformServiceRate: '', // 平台服务费率
        remainingDays: '', // 剩余天数
        payServiceCost: '', //应付服务费（元）
        recBankAccount: '', // 收款账号
        recLegalNumber: '', // 收款账户行号
        recBankName: '', // 收款账户银行
        transferFlag: '',// 转让标记
        transferFlagName: '', // 转让标记
        discountType: '', // 贴现种类
        settlementModel: '', // 结算方式
        payInterestType: '', // 付息方式
        remark: '', // 备注
        lxAccountName: '', // 账户名称
        lxBankName: '', // 银行全称
        lxBankAccount: '', // 银行账户
        lxLegalNumber: '', // 大额行号
        payVouchersUrl: '', // 缴纳凭证
        payTime:'', // 缴纳时间
        isTechnologyServiceProtocol: false, // 判断技术服务费协议是否被选中
        technologyServiceProtocol: '', // 技术服务费协议
      },
      discountFormRules: {
        orgFullCnName: [// 贴现机构
          { required: true, message: '贴现机构必填', trigger: 'blur' }
        ],
        orgBankCode: [// 贴现行号
          { required: true, message: '贴现行号必填', trigger: 'blur' }
        ],
        discountAmount: [// 贴现金额（元）
          { required: true, message: '贴现金额必填', trigger: 'blur' }
        ],
        applyTime: [// 申请日期
          { required: true, message: '申请日期必填', trigger: 'blur' }
        ],
        discountRate: [// 贴现利率
          { required: true, message: '贴现利率必填', trigger: 'blur' }
        ],
        arrivalAmount: [// 到账金额（元）
          { required: true, message: '到账金额必填', trigger: 'blur' }
        ],
        platformServiceRate: [// 平台服务费率
          { required: true, message: '平台服务费率必填', trigger: 'blur' }
        ],
        payServiceCost: [//应付服务费（元）
          { required: true, message: '应付服务费必填', trigger: 'blur' }
        ],
        recBankAccount: [// 收款账号
          { required: true, message: '收款账号必填', trigger: 'change' }
        ],
        recLegalNumber: [// 收款账户行号
          { required: true, message: '收款账户行号必填', trigger: 'blur' }
        ],
        recBankName: [// 收款账户银行
          { required: true, message: '收款账户银行必填', trigger: 'blur' }
        ],
        transferFlag: [// 转让标记
          { required: true, message: '转让标记必填', trigger: 'change' }
        ],
        discountType: [// 贴现种类
          { required: true, message: '贴现种类必填', trigger: 'blur' }
        ],
        settlementModel: [// 结算方式
          { required: true, message: '结算方式必填', trigger: 'blur' }
        ],
        payInterestType: [// 付息方式
          { required: true, message: '付息方式必填', trigger: 'blur' }
        ],
        lxAccountName: [// 账户名称
          { required: true, message: '账户名称必填', trigger: 'blur' }
        ],
        lxBankName: [// 银行全称
          { required: true, message: '银行全称必填', trigger: 'blur' }
        ],
        lxBankAccount: [// 银行账户
          { required: true, message: '银行账户必填', trigger: 'blur' }
        ],
        lxLegalNumber: [// 大额行号
          { required: true, message: '大额行号必填', trigger: 'blur' }
        ],
      },
      discountTicketInfoVOList: [], // 票据基本信息
      accountNumberList: [], // 收款账号列表
    }

  },
  methods: {
    // 获取数据
    async getDiscountInfo(){
      const {data: res} = await this.$auth.get('/cpiaoju-ticket/discount/applyDiscount', {params: {id: this.id}});
      if (res.code !== 200) return this.$message.error(res.msg)
      this.discountForm = {...res.data}
      // 票据基本信息
      this.discountTicketInfoVOList = res.data.discountTicketInfoVOList
    },
    // 获取收款账号
    async getPassBank(){
      const {data: res} = await this.$auth.get('/cpiaoju-ticket/front-bank-ext/getPassBank');
      if(res.code !== 200) return this.$message.error(res.msg)
      this.accountNumberList = res.data
      console.log(res)
    },
    // 监听收款账号的改变
    accountNumberChange(value){
      let result = this.accountNumberList.filter(item => item.recBankAccount === value);
      console.log(result)
      this.discountForm.receiptAccountId = result[0].id // 收款账号ID
      this.discountForm.recLegalNumber = result[0].recLegalNumber
      this.discountForm.recBankName = result[0].recBankName
    },
    // 根据贴现机构获取
    async getDiscountOrg(){
      let params = {
        orgFullCnName: this.discountForm.orgFullCnName,
        billType: 'AC01'
      }
      const {data: res} = await this.$auth.get('/cpiaoju-ticket/discount/getDiscountOrg', {params})
      console.log(res)
      if(res.code !== 200) return this.$message.error(res.msg)
      this.discountForm.discountOrgId = res.data.discountOrgId // 贴现机构ID主键
      this.discountForm.orgBankCode = res.data.orgBankCode // 贴现行号
      this.discountForm.discountRate = res.data.discountRate // 贴现利率
      this.discountForm.platformServiceRate = res.data.platformServiceRate // 平台服务费率
      this.discountForm.settlementModel = res.data.settlementModel // 结算方式
    },
    // 监听贴现金额
    getDiscountAmount(){
      // 计算到账金额
      this.getArrivalAmount()
      // 计算应付服务费
      this.getPayServiceCost()
    },
    // 计算到账金额
    getArrivalAmount(){
      // 到账金额 = 贴现金额-【贴现金额*贴现利率*剩余天数/360】
      let {discountAmount,discountRate,remainingDays} = this.discountForm
      let result = Number(discountAmount - (discountAmount * discountRate / 100 * remainingDays / 360))
      this.discountForm.arrivalAmount = result.toFixed(2)
    },
    // 计算应付服务费
    getPayServiceCost(){
      // 贴现金额*平台服务费率*剩余天数/360
      let {discountAmount, platformServiceRate,remainingDays} = this.discountForm
      let result = Number(discountAmount * platformServiceRate / 100 * remainingDays / 360)
      console.log(result)
      this.discountForm.payServiceCost = result.toFixed(2)
    },
    // 返回
    handleBack(){
      this.$router.push({name: 'discountApply'})
    },
    // 下一步
    handleSave(){
      this.$refs.discountFormRef.validate(async valid => {
        if(!valid) return;
        if(!this.discountForm.isTechnologyServiceProtocol) return this.$message.error('请同意签署协议')
        let params = {
          page: 1,
          id: this.id,
          discountOrgId: this.discountForm.discountOrgId,
          discountAmount: this.discountForm.discountAmount,
          receiptAccountId: this.discountForm.receiptAccountId,
          transferFlag: this.discountForm.transferFlag,
          discountRate: this.discountForm.discountRate,
          platformServiceRate: this.discountForm.platformServiceRate,
          arrivalAmount: this.discountForm.arrivalAmount,
          payServiceCost: this.discountForm.payServiceCost,
          discountType: this.discountForm.discountType,
          settlementModel: this.discountForm.settlementModel,
          remark: this.discountForm.remark,
          payVouchersUrl: this.discountForm.payVouchersUrl,
          payTime: this.discountForm.payTime
        }
        const {data: res} = await this.$auth.post("/cpiaoju-ticket/discount/addOrUpd", this.$qs.stringify(params))
        if (res.code !== 200) return this.$message.error(res.msg)
        this.$emit('handleSubmit', {activeStep: 2, id: this.id})
      })
    },
    // 票面预览
    async handleFaceView(){
      let params = {
        paperBagNo: this.discountTicketInfoVOList[0].packageNo,
        section: this.discountTicketInfoVOList[0].ticketRange
      }
      const res = await this.$auth.post('/cpiaoju-ticket/common/ticketFaceView', this.$qs.stringify(params), {responseType: 'blob'});
      if (res.data.type === "application/pdf"){
        const blob = res.data;
        let link = document.createElement('a');
        link.setAttribute('target', '_blank');
        link.href = window.URL.createObjectURL(blob);
        link.click()
        URL.revokeObjectURL(link.href);
      }else{
        return this.$message.error('暂无票面信息')
      }
    },
    // 票面下载
    async handleFaceDownload(){
      let params = {
        paperBagNo: this.discountTicketInfoVOList[0].packageNo,
        section: this.discountTicketInfoVOList[0].ticketRange
      }
      const res = await this.$auth.post('/cpiaoju-ticket/common/ticketFaceDownload', this.$qs.stringify(params), {responseType: 'blob'});
      console.log(res)
      if (res.headers['content-disposition']){
        const blob = res.data;
        const fileName = window.decodeURI(res.headers['content-disposition'].split(';')[1].split('=')[1]); //这是下载的关键
        let link = document.createElement('a');
        let url = URL.createObjectURL(blob);
        link.href = url;
        link.download = fileName
        console.log(link)
        link.click()
        URL.revokeObjectURL(url)
      }else{
        return this.$message.error('暂无票面信息')
      }
    },
  }
}
</script>

<style lang="less" scoped>
  .title{
    h3{
      display: inline-block;
      margin-right: 10px;
    }
  }
  //表单
  .discount-ruleForm{
    margin-top: 30px;
    h3{
      margin-top: 10px;
    }
    .el-form-item{
      width: 48%;
      margin-top: 10px;
      margin-bottom: 10px !important;
      box-sizing: border-box;
      /deep/ .el-form-item__content{
        width: 64%;
        .el-select{
          width: 100%;
        }
      }
    }
  }
  .upload-demo{
    img{
      width: 100%;
      height: 100%;
    }
  }
  .btn{
    text-align: center;
    margin-top: 50px;
    .el-button{
      min-width: 120px;
    }
  }
  // 签署协议
  .licence{
    p{
      // background: #FAFAFA;
      width: 100%;
      height: 54px;
      margin-top: 20px;
      padding-left: 20px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      /deep/ .el-checkbox__label{
        font-weight: bold;
      }
    }
    a{
      color: #C70009;
      margin-top: 5px;
      margin-left: 40px;
    }
  }
  // 温馨提示
  .upload-explain{
			background: #FFFBE6;
			width: 100%;
			line-height: 1.8;
			padding: 10px 15px;
			margin-top: 20px;
			border: 1px solid #FFFBE6;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			justify-content: space-evenly;
			span{
				display: flex;
				line-height: 1.8;
				color: #666;
				padding-left: 20px;
			}
			img{
				vertical-align: -2px;
				margin-right: 5px;
			}
		}
</style>